import React from 'react';
import { IconButton, SvgIcon, Popover, Paper, Typography } from '@mui/material';
import styles from '../../Steppers/AlertStepper.module.scss';
import { ALERT_DETAILS } from '../../../../containers/Alerts/constants.alerts';

class TamperAlert extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            popoverOpen: false,
            anchorEl: null,
        };
    }

    handlePopoverOpen = (event) => {
        this.setState({
            popoverOpen: true,
            anchorEl: event.currentTarget,
        });
    }

    closePopOver = () => {
        this.setState({
            popoverOpen: false,
            anchorEl: null,
        });
    }

    render() {
        const { popoverOpen, anchorEl } = this.state;
        return (
            <div className={styles['.step-content']}>
                <div className={styles['vertical-divider']}>
                    <div className={styles['step-rightside']}>
                        <div>
                            <div>
                                {ALERT_DETAILS.LABEL.TAMPER_EVENT}
                                <IconButton
                                    ref={(node) => { this.anchorEl = node; }}
                                    className={styles.iconBtn}
                                    disableRipple
                                    onMouseEnter={(event) => this.handlePopoverOpen(event)}
                                    onMouseLeave={() => this.closePopOver()}
                                >
                                    <SvgIcon style={{ fontSize: '18px' }}>
                                        <path d="M15.07,11.25L14.17,12.17C13.45,12.89 13,13.5 13,15H11V14.5C11,13.39 11.45,12.39 12.17,11.67L13.41,10.41C13.78,10.05 14,9.55 14,9C14,7.89 13.1,7 12,7A2,2 0 0,0 10,9H8A4,4 0 0,1 12,5A4,4 0 0,1 16,9C16,9.88 15.64,10.67 15.07,11.25M13,19H11V17H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12C22,6.47 17.5,2 12,2Z" />
                                    </SvgIcon>
                                </IconButton>
                                <Popover
                                    style={{ pointerEvents: 'none', marginLeft: '-40px' }}
                                    open={popoverOpen}
                                    anchorEl={anchorEl}
                                    onClose={() => this.closePopOver()}
                                    disableRestoreFocus
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                >
                                    <Paper elevation={1}>
                                        <Typography style={{ padding: '15px 35px 15px 15px' }}>
                                            Supported devices are Flex Battery, Flex 2 and Flex 2+.
                                        </Typography>
                                    </Paper>
                                </Popover>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TamperAlert;
