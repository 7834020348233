exports.ALERT = {
    CATEGORY: {
        SAFETY: 'Safety',
        SAFETY_ID: 'Safety',
        PRODUCTIVITY: 'Productivity',
        PRODUCTIVITY_ID: 'Productivity',
        ASSET_HEALTH: 'Asset Health',
        ASSET_HEALTH_ID: 'AssetHealth',
        MONITORING: 'Monitoring',
        MONITORING_ID: 'Monitoring',
    },
    HARD_BRAKING_ACCELERATION_ALERT: {
        CODE: 'localFleetHardBrakingAccelerationAlert',
        NAME: 'Hard Braking & Acceleration Alert',
        DESCRIPTION: 'Sends an alert when the selected asset(s) accelerate or brake suddenly.',
        IMAGE_LOCATION: './images/alerts/HardBrakingAccelerationAlert.svg',
        ID_POSTFIX: 'HardBreakingAccelerationAlert',
    },
    ODD_HOURS_ALERT: {
        CODE: 'localFleetOddHoursAlertAlert',
        NAME: 'Odd Hours Alert',
        DESCRIPTION: 'Sends an alert if the selected asset(s) record an event outside of normal operating hours. Odd hours are set by the user.',
        IMAGE_LOCATION: './images/alerts/OddHoursAlert.svg',
        ID_POSTFIX: 'OddHoursAlert',
    },
    POSTED_SPEED_LIMIT_ALERT: {
        CODE: 'localFleetSpeedLimitAlertAlert',
        NAME: 'Posted Speed Limit Alert',
        DESCRIPTION: 'Sends an alert if the selected asset(s) exceeds the maximum posted speed threshold.',
        IMAGE_LOCATION: './images/alerts/PostedSpeedLimitAlert.svg',
        ID_POSTFIX: 'PostedSpeedLimitAlert',
    },
    SPEED_THRESHOLD_ALERT: {
        CODE: 'localFleetSpeedThresholdAlertAlert',
        NAME: 'Speed Threshold Alert',
        DESCRIPTION: 'Sends an alert if the selected asset(s) exceeds a user-set maximum speed threshold.',
        IMAGE_LOCATION: './images/alerts/SpeedThresholdAlert.svg',
        ID_POSTFIX: 'SpeedThresholdAlert',
    },
    UNAUTHORIZED_MOVEMENT: {
        CODE: 'localFleetUnauthorizedMovementAlertAlert',
        NAME: 'Unauthorized Movement',
        DESCRIPTION: 'Sends an alert if the selected asset(s) record movement without the ignition being on.',
        IMAGE_LOCATION: './images/alerts/UnauthorizedMovement.svg',
        ID_POSTFIX: 'UnauthorizedMovement',
    },
    SEATBELT_STATUS_ALERT: {
        CODE: 'localFleetSeatbeltAlert',
        NAME: 'Seatbelt Status Alert',
        DESCRIPTION: 'Sends an alert when the driver has not latched their seatbelt in the amount of time specified.',
        IMAGE_LOCATION: './images/alerts/SeatbeltStatusAlert.svg',
        ID_POSTFIX: 'SeatbeltStatusAlert',
    },
    IDLE_ALERT: {
        CODE: 'localFleetIdleAlertAlert',
        NAME: 'Idle Alert',
        DESCRIPTION: 'Sends an alert when the selected assets(s) have been idling more than a set number of minutes.',
        IMAGE_LOCATION: './images/alerts/IdleAlert.svg',
        ID_POSTFIX: 'IdleAlert',
    },
    STOP_ALERT: {
        CODE: 'localFleetStopAlertAlert',
        NAME: 'Stop Alert',
        DESCRIPTION: 'Sends an alert when the selected asset(s) stop for more than a set number of minutes',
        IMAGE_LOCATION: './images/alerts/StopAlert.svg',
        ID_POSTFIX: 'StopAlert',
    },
    INPUT_ALERT: {
        CODE: 'localFleetInputAlert',
        NAME: 'Input Alert',
        DESCRIPTION: 'Sends an alert when one of the user-defined inputs is active or inactive.',
        IMAGE_LOCATION: './images/alerts/InputAlert.svg',
        ID_POSTFIX: 'InputAlert',
    },
    LANDMARK_ARRIVAL_DEPARTURE_ALERT: {
        CODE: 'localFleetLandmarkArrivalDepartureAlertAlert',
        NAME: 'Landmark Arrival & Departure Alert',
        DESCRIPTION: 'Sends an alert when the selected asset(s) enter or leave the selected landmark(s).',
        IMAGE_LOCATION: './images/alerts/Landmark_Arrival_Departure Alert.svg',
        ID_POSTFIX: 'LandmarkArrivalDepartureAlert',
    },
    EXTERNAL_DEVICE_POWER_ON_ALERT: {
        CODE: 'localFleetPowerReconnectAlert',
        NAME: 'Device Power Connect Alert',
        DESCRIPTION: 'Sends an alert when the selected device(s) power is on after having completely lost power for a period of time.',
        IMAGE_LOCATION: './images/alerts/External_Device_Power_On_Alert.svg',
        ID_POSTFIX: 'DevicePowerConnectAlert',
    },
    DEVICE_POWER_DISCONNECT_ALERT: {
        CODE: 'devicePowerDisconnectAlert',
        NAME: 'Device Power Disconnect Alert',
        DESCRIPTION: 'Sends an alert when the selected device(s) is no longer receiving external power.',
        IMAGE_LOCATION: './images/alerts/Device_Power_Disconnect_Alert.svg',
        ID_POSTFIX: 'DevicePowerDisconnectAlert',
    },
    TEMPERATURE_ALERT: {
        CODE: 'localFleetTemperatureAlertAlert',
        NAME: 'Temperature Alert',
        DESCRIPTION: 'Sends an alert when inside of selected asset(s) cargo temperature goes past selected temperatures.',
        IMAGE_LOCATION: './images/alerts/Temperature_Alert.svg',
        ID_POSTFIX: 'TemperatureAlert',
    },
    FUEL_TANK_ALERT: {
        CODE: 'localFleetFuelTankAlert',
        NAME: 'Fuel Tank Alert',
        DESCRIPTION: 'Sends an alert when the fuel tank level of selected asset(s) drops below the specified threshold.',
        IMAGE_LOCATION: './images/alerts/Fuel_Tank_Alert.svg',
        ID_POSTFIX: 'FuelTankAlert',
    },
    TAMPER_ALERT: {
        CODE: 'localFleetTamperAlert',
        NAME: 'Tamper Alert',
        DESCRIPTION: 'Sends an alert when the selected device(s) are tampered with or tilted.',
        IMAGE_LOCATION: './images/alerts/TamperAlert.svg',
        ID_POSTFIX: 'TamperAlert',
    },
    DTC_ALERT: {
        CODE: 'maintenanceDtcAlert',
        NAME: 'DTC Alert',
        DESCRIPTION: 'Sends an alert if the selected asset(s) experiences a diagnostic trouble code.',
        IMAGE_LOCATION: './images/alerts/Dtc_Alert.svg',
        ID_POSTFIX: 'DtcAlert',
    },
    LOW_ASSET_BATTERY_ALERT: {
        CODE: 'localFleetLowBatteryAlert',
        NAME: 'Low Battery Alert',
        DESCRIPTION: 'Sends an alert when the selected asset(s) battery power is lower than the user-specified voltage threshold.',
        IMAGE_LOCATION: './images/alerts/Low_Asset_Battery_Alert.svg',
        ID_POSTFIX: 'LowAssetBatteryAlert',
    },
    // LOW_DEVICE_BATTERY_ALERT: {
    //     CODE: 'localFleetLowBatteryAlert',
    //     NAME: 'Low Battery Alert',
    //     DESCRIPTION: 'Sends an alert when the battery power is below the recommended threshold.',
    //     IMAGE_LOCATION: './images/alerts/Low_Device_Battery_Alert.svg',
    //     ID_POSTFIX: 'LowBatteryAlert',
    // },
    TIRE_PRESSURE_ALERT: {
        CODE: 'localFleetTirePressureAlert',
        NAME: 'Tire Pressure Alert',
        DESCRIPTION: 'Sends an alert when the tire pressure of selected asset(s) drops below 20% of the recommended threshold.',
        IMAGE_LOCATION: './images/alerts/Tire_Pressure_Alert.svg',
        ID_POSTFIX: 'TirePressureAlert',
    },
    ENGINE_OIL_LIFE_ALERT: {
        CODE: 'localFleetEngineOilLifeAlert',
        NAME: 'Engine Oil Life Alert',
        DESCRIPTION: 'Sends an alert when the engine oil life of selected asset(s) drops below 20%.',
        IMAGE_LOCATION: './images/alerts/Engine_Oil_Life_Alert.svg',
        ID_POSTFIX: 'EngineOilLifeAlert',
    },
};

exports.STEPS = {
    STEP1: 'Alert Details',
    STEP2: 'Asset Criteria',
    STEP3: 'Recipients',
    STEP4: 'Schedule',
    ADD_ALERT: 'Add Alert',
    EDIT_ALERT: 'Edit Alert',
    REQ_FIELD: '* Required Field',
    CANCEL: 'Cancel',
    BACK: 'Back',
    SAVE: 'Save',
    UPDATE: 'Update',
    NEXT: 'Next',
};

exports.ALERT_DETAILS = {
    LOADING: 'Loading...',
    PLEASE_SELECT: 'Please select',
    ALERT_NAME: 'Alert Name',
    ALERT_NAME_UNIQUE: 'Alert name must be unique',
    ALERT_NAME_REQUIRED: 'Alert name is required',
    LABEL: {
        HARD_BREAKING_EVENT: 'Alert on Hard Braking Event',
        HARD_ACCELERATION_EVENT: 'Alert on Hard Acceleration Event',
        ODD_HOURS_EVENTS: 'Re-alert me of odd hour events after',
        ODD_HOURS_MINUTES: 'Minutes*',
        ODD_HOURS_FREQUENCY: '* If no re-alert frequency is set, a re-alert will be sent with every device event applicable to the alert setting.',
        POSTED_SPEED_LIMIT: 'Alert me when a vehicle exceeds the posted speed limit by',
        SPEED_THRESHOLD: 'Alert me of speeds exceeding',
        MPH: 'Mph',
        KMPH: 'Kmph',
        IDLE_EVENT: 'Alert me of an idle event after',
        IDLE_EVENT_REALERT: 'Re-alert me of an Idle event after',
        IDLE_MINUTES: 'Minutes',
        INPUT_EVENT: 'Alert me of the following input statuses',
        INPUT_1: 'Input 1',
        INPUT_2: 'Input 2',
        INPUT_3: 'Input 3',
        INPUT_4: 'Input 4',
        INPUT_ACTIVE: 'Active',
        INPUT_INACTIVE: 'Inactive',
        STOP_EVENT: 'Alert me of stops after',
        STOP_DAYS: 'Days',
        STOP_HOURS: 'Hours',
        STOP_MINUTES: 'Minutes',
        LANDMARK_GROUP: 'Landmark Group',
        LANDMARK: 'Landmark',
        ALL_GROUPS: 'All Groups',
        ALL_LANDMARKS: 'All Landmarks',
        CLEAR: 'CLEAR',
        ADD: 'Add',
        ALERT_ON_ARRIVAL: 'Alert on Arrival',
        ALERT_ON_DEPARTURE: 'Alert on Departure',
        POWER_RECONNECT: 'Alert on Power Reconnect',
        POWER_DISCONNECT: 'Alert on Power Disconnect',
        TEMP_CRITICAL_MIN: 'Critical Min Temperature',
        TEMP_CRITICAL_MAX: 'Critical Max Temperature',
        TEMP_CAUTION_MIN: 'Caution Min Temperature',
        TEMP_CAUTION_MAX: 'Caution Max Temperature',
        TEMP_HOLD_TIME: 'Hold Time Before Alerted',
        TEMP_MINUTES: 'Minutes',
        FUEL_TANK_BELOW: 'Alert when fuel tank is below',
        FUEL_TANK_REALERT_OFF: 'Re-alert on every trip if the fuel level is below the set threshold',
        FUEL_TANK_REALERT_ON: 'Re-alert me every:',
        FUEL_TANK_LEVEL_BELOW: 'within each trip if the fuel level is below the set threshold',
        FUEL_TANK_MINUTES: 'Minutes',
        LOW_ASSET_BATTERY: 'Alert me when my vehicle battery voltage drops to',
        LOW_ASSET_BATTERY_INTERNAL_DEVICE: 'Also alert me when the tracker battery drops below the recommended threshold. \nNote: This applies only to devices that have an internal battery.',
        TIRE_PRESSURE_OVER: 'Alert on Tire Pressure Overinflation Events',
        TIRE_PRESSURE_GREATER_RECOMMENDED: 'Occurs when fleet locate receives a tire pressure reading > 20% than recommended',
        TIRE_PRESSURE_UNDER: 'Alert on Tire Pressure Underinflation Events',
        TIRE_PRESSURE_LESSER_RECOMMENDED: 'Occurs when fleet locate receives a tire pressure reading < 20% than recommended',
        TIRE_PRESSURE_OVER_UNDER: 'Re-Alert me of tire over/under inflation after:',
        TIRE_PRESSURE_DAYS: 'Days',
        TIRE_PRESSURE_NOTE: 'NOTE: this alert utilizes manufacturer recommended tire pressure.',
        TIRE_PRESSURE_EX: 'EX: with 50psi on the recommended value, >60psi will result in overinflation alert and <40psi in underinflation alert.',
        ENGINE_OIL_LIFE_20: 'This alert occurs when Engine Oil Life falls below 20%',
        ENGINE_OIL_LIFE_REALERT: 'Re-alert me of continuous Engine Oil Life events after:',
        ENGINE_OIL_LIFE_DAYS: 'Days',
        SEATBELT_EVENT: 'Alert me when the driver takes off their seatbelt while the vehicle is moving after:',
        SEATBELT_EVENT_REALERT: 'Re-alert me if the seatbelt stays off after:',
        SEATBELT_MINUTES: 'Minutes',
        SEATBELT_REALERT_FREQUENCY: '* If no re-alert frequency is set, a re-alert will be sent with every device event applicable to the alert setting.',
        TAMPER_EVENT: 'This alert is only applicable to specific devices',
    },
    MESSAGES: {
        HARD_ACCELERATION_VALID: 'Please select atleast 1 event',
        ODD_HOURS_LESS_MIN: 'Please enter minutes greater than ',
        ODD_HOURS_GREAT_MAX: 'Please enter minutes lesser than ',
        SPEED_LIMIT_LESS_MIN: 'Please enter speed limit greater than ',
        SPEED_LIMIT_GREAT_MAX: 'Please enter speed limit lesser than ',
        SPEED_THRESHOLD_LESS_MIN: 'Please enter speed limit greater than ',
        SPEED_THRESHOLD_GREAT_MAX: 'Please enter speed limit lesser than ',
        SBLT_LESS_MIN: 'Please enter seatbelt minutes greater than ',
        SBLT_GREAT_MAX: 'Please enter seatbelt minutes lesser than ',
        SBLT_REALERT_LESS_MIN: 'Please enter seatbelt realert minutes greater than ',
        SBLT_REALERT_GREAT_MAX: 'Please enter seatbelt realert minutes lesser than ',
        IDLE_LESS_MIN: 'Please enter idle minutes greater than ',
        IDLE_GREAT_MAX: 'Please enter idle minutes lesser than ',
        IDLE_REALERT_LESS_MIN: 'Please enter idle realert minutes greater than ',
        IDLE_REALERT_GREAT_MAX: 'Please enter idle realert minutes lesser than ',
        STOP_DAYS_LESS_MIN: 'Please enter stop days greater than ',
        STOP_DAYS_GREAT_MAX: 'Please enter stop days lesser than ',
        STOP_HOURS_LESS_MIN: 'Please enter stop hours greater than ',
        STOP_HOURS_GREAT_MAX: 'Please enter stop hours lesser than ',
        STOP_MINUTES_LESS_MIN: 'Please enter stop minutes greater than ',
        STOP_MINUTES_MIN: 'The minimum value for a stop alert is 5 minutes',
        STOP_MINUTES_GREAT_MAX: 'Please enter stop minutes lesser than ',
        INPUT_VALID: 'At least one input status must be selected.',
        LANDMARK_ARRIVAL_DEPARTURE_VALID: 'Please select atleast 1 event',
        CRT_MIN_LESS_MIN: 'Please enter Critical Min Temperature greater than ',
        CRT_MIN_GREAT_MAX: 'Please enter Critical Min Temperature lesser than ',
        CRT_MAX_LESS_MIN: 'Please enter Critical Max Temperature greater than ',
        CRT_MAX_GREAT_MAX: 'Please enter Critical Max Temperature lesser than ',
        CUT_MIN_LESS_MIN: 'Please enter Caution Min Temperature greater than ',
        CUT_MIN_GREAT_MAX: 'Please enter Caution Min Temperature lesser than ',
        CUT_MAX_LESS_MIN: 'Please enter Caution Max Temperature greater than ',
        CUT_MAX_GREAT_MAX: 'Please enter Caution Max Temperature lesser than ',
        RT_LESS_MIN: 'Please enter hold time greater than ',
        RT_GREAT_MAX: 'Please enter hold minutes lesser than ',
        CRT_MIN_LESS_MAX: 'Please enter Critical Min Temperature lesser than Critical Max Temperature',
        CUT_MIN_LESS_MAX: 'Please enter Caution Min Temperature lesser than Caution Max Temperature',
        CUT_MIN_LESS_CRT_MIN: 'Please enter Caution Min Temperature greater than Critical Min Temperature',
        CUT_MAX_GREAT_CRT_MAX: 'Please enter Caution Max Temperature lesser than Critical Max Temperature',
        FT_LESS_MIN: 'Please enter Fuel Tank percentage greater than ',
        FT_GREAT_MAX: 'Please enter Fuel Tank percentage lesser than ',
        FT_REALERT_LESS_MIN: 'Please enter idle realert minutes greater than ',
        FT_REALERT_GREAT_MAX: 'Please enter idle realert minutes lesser than ',
        TP_VALID: 'Please select atleast 1 event',
        TP_REALERT_LESS_MIN: 'Please enter Tire Pressure realert days greater than ',
        TP_REALERT_GREAT_MAX: 'Please enter Tire Pressure realert days lesser than ',
        EO_REALERT_LESS_MIN: 'Please enter Engine Oil realert days greater than ',
        EO_REALERT_GREAT_MAX: 'Please enter Engine Oil realert days lesser than ',
    },
    VALUE: {
        // ODD HOURS
        OHM_DEF: 10,
        OHM_MIN: 10,
        OHM_MAX: Number.MAX_SAFE_INTEGER,
        // POSTED SPEED LIMIT
        PSL_DEF: 5,
        PSL_MIN: 1,
        PSL_MAX: Number.MAX_SAFE_INTEGER,
        // SPEED THRESHOLD
        ST_DEF: 65,
        ST_MIN: 10,
        ST_MAX: Number.MAX_SAFE_INTEGER,
        // IDLE
        IDL_DEF: 10,
        IDL_MIN: 5,
        IDL_MAX: Number.MAX_SAFE_INTEGER,
        IDL_R_DEF: 10,
        IDL_R_MIN: 1,
        IDL_R_MAX: Number.MAX_SAFE_INTEGER,
        // STOP
        STD_DEF: 0,
        STD_MIN: 0,
        STD_MAX: Number.MAX_SAFE_INTEGER,
        STH_DEF: 0,
        STH_MIN: 0,
        STH_MAX: 23,
        STM_DEF: 5,
        STM_MIN: 0,
        STM_MAX: 59,
        // TEMPERATURE
        CRT_MIN_DEF: 20,
        CRT_MIN_MIN: -40,
        CRT_MIN_MAX: 176,
        CRT_MAX_DEF: 48,
        CRT_MAX_MIN: -40,
        CRT_MAX_MAX: 176,
        CUT_MIN_DEF: 28,
        CUT_MIN_MIN: -40,
        CUT_MIN_MAX: 176,
        CUT_MAX_DEF: 40,
        CUT_MAX_MIN: -40,
        CUT_MAX_MAX: 176,
        RT_DEF: 60,
        RT_MIN: 0,
        RT_MAX: Number.MAX_SAFE_INTEGER,
        // FUEL TANK
        FT_DEF: 10,
        FT_MIN: 1,
        FT_MAX: 99,
        FT_RT_DEF: 5,
        FT_RT_MIN: 1,
        FT_RT_MAX: Number.MAX_SAFE_INTEGER,
        // TIRE PRESSURE
        TP_DEF: 1,
        TP_MIN: 1,
        TP_MAX: 7,
        // ENGINE OIL
        EO_DEF: 5,
        EO_MIN: 1,
        EO_MAX: 30,
        // SEATBELT
        SBLT_DEF: 5,
        SBLT_MIN: 2,
        SBLT_MAX: 360,
        SBLT_R_DEF: 10,
        SBLT_R_MIN: 1,
        SBLT_R_MAX: 360,
        // LOW BATTERY ALERT
        LBA_DEF: 11.6,
    },
};

exports.ALERT_SCHEDULE = {
    LABEL: {
        CREATE_ALERT_SCHEDULE: 'Create Alert Schedule (Optional)',
        START_DAY: 'Start Day',
        START_TIME: 'Start Time',
        END_DAY: 'End Day',
        END_TIME: 'End Time',
        ADD: 'Add',
    },
    ALERT_SCHEDULE_TIME: {
        DAYS_FOR_ALERT_SCHEDULE: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        TIME_FOR_ALERT_SCHEDULE: ['12:00 AM', '12:15 AM', '12:30 AM', '12:45 AM', '1:00 AM', '1:15 AM', '1:30 AM', '1:45 AM', '2:00 AM', '2:15 AM', '2:30 AM', '2:45 AM', '3:00 AM', '3:15 AM', '3:30 AM', '3:45 AM', '4:00 AM', '4:15 AM', '4:30 AM', '4:45 AM', '5:00 AM', '5:15 AM', '5:30 AM', '5:45 AM', '6:00 AM', '6:15 AM', '6:30 AM', '6:45 AM', '7:00 AM', '7:15 AM', '7:30 AM', '7:45 AM', '8:00 AM', '8:15 AM', '8:30 AM', '8:45 AM', '9:00 AM', '9:15 AM', '9:30 AM', '9:45 AM', '10:00 AM', '10:15 AM', '10:30 AM', '10:45 AM', '11:00 AM', '11:15 AM', '11:30 AM', '11:45 AM', '12:00 PM', '12:15 PM', '12:30 PM', '12:45 PM', '1:00 PM', '1:15 PM', '1:30 PM', '1:45 PM', '2:00 PM', '2:15 PM', '2:30 PM', '2:45 PM', '3:00 PM', '3:15 PM', '3:30 PM', '3:45 PM', '4:00 PM', '4:15 PM', '4:30 PM', '4:45 PM', '5:00 PM', '5:15 PM', '5:30 PM', '5:45 PM', '6:00 PM', '6:15 PM', '6:30 PM', '6:45 PM', '7:00 PM', '7:15 PM', '7:30 PM', '7:45 PM', '8:00 PM', '8:15 PM', '8:30 PM', '8:45 PM', '9:00 PM', '9:15 PM', '9:30 PM', '9:45 PM', '10:00 PM', '10:15 PM', '10:30 PM', '10:45 PM', '11:00 PM', '11:15 PM', '11:30 PM', '11:45 PM'],
        START_DAY: '',
        START_TIME: '',
        END_DAY: '',
        END_TIME: '',
    },
    ALERT_SCHEDULE_ERRORS: {
        START_DAY_VALUE_EMPTY: 'Start Day Cannot Be Empty',
        START_TIME_VALUE_EMPTY: 'Start Time Cannot Be Empty',
        END_DAY_VALUE_EMPTY: 'End Day Cannot Be Empty',
        END_TIME_VALUE_EMPTY: 'End Time Cannot Be Empty',
        END_TIME_LESSTHAN_START_TIME: 'End Time Must Occur After Start Time',
        DEFINE_SCHEDULE: 'You must have 1 schedule defined.',
    },
};

exports.ALERT_RECIPIENT = {
    LABEL: {
        ADD: 'Add',
        COMMENTS: 'Comments',
        EMAIL_OR_PHONE: 'Email or Phone #',
        NO_RESULTS: '',
    },
    ERROR: {
        EMPTY: 'A valid phone number (555-555-1212) or email address is required',
        INVALID: 'A valid phone number (555-555-1212) or email address is required',
    },
};

exports.POWER_DISCONNECT_ASSET_FILTER = {
    property: 'deviceModelName',
    operator: 'in',
    value: ['Calamp LMU1100', 'Calamp LMU1220', 'Calamp TTU1200', 'Calamp TTU1220', 'Calamp TTU1220 CDMA', 'Calamp TTU1230HP', 'Calamp TTU12x0', 'Xirgo SnapTrack', 'XT2400', 'Montage Arsenal Puma FL Flex LTE', 'XT2500', 'A-Tel Simba FL Flex 2 AT&T', 'A-Tel Simba FL Flex 2 Sprint', 'A-Tel Simba FL Flex 2 Verizon', 'A-Tel Nala Flex2PL-SL-M-V', 'A-Tel Nala Flex2PL-SL-L-A', 'A-Tel Nala Flex2PL-SP-L-A', 'A-Tel Nala Flex2PL-CN-L-A'],
};
